<template>
  <v-footer
    class="text-md-center"
    fixed
    app
  >
    <span v-html="versionStr()" />
  </v-footer>
</template>

<style scoped>
  span {
    width: 100%;
  }
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  computed: {
    ...mapGetters('info', [
      'getVersion',
    ]),
  },
  methods: {
    versionStr() {
      // Get current year.
      const currYear = new Date().getFullYear();
      return 'All content copyright &copy; 2015-'
        .concat(`${currYear} `)
        .concat('ImageWare Systems Inc. ')
        .concat('All rights reserved. AP Version: ')
        .concat(this.getVersion);
    },
  },
};
</script>
